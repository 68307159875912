import React from 'react';
import Generator from './generator.js'
import './App.css';

function App() {
  return (
    <div className="App">
      <Generator/>
    </div>
  );
}

export default App;
